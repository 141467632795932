<template>
  <v-container>
    <v-snackbar color="primary" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <!-- If error -->
    <v-row v-if="error" align="center" justify="center">
      <v-alert type="error">
        {{ error }}
      </v-alert>
    </v-row>

    <v-form v-else @submit.prevent v-model="valid" ref="loginForm">
      <v-card>
        <v-row align="start" justify="center">
          <!-- PDF settings heading -->
          <v-col cols="12" sm="12" md="10">
            <v-card-title>
              <v-icon class="mr-2">mdi-chevron-right</v-icon> PDF Settings:
            </v-card-title>
            <v-divider></v-divider>
          </v-col>
          <v-card-text>
            <v-row align="center" justify="center">
              <!-- Select Group Dropdown -->
              <v-col cols="12" sm="12" md="10" class="py-0">
                <div class="d-flex align-center">
                  <v-icon>mdi-file-pdf-box</v-icon>
                  <span class="ml-1 mr-md-2"> PDF Group: </span>
                  <v-select
                    label="Select PDF Group"
                    class="px-md-4 px-2 ml-1 text-capitalize"
                    v-model="selectedGroup"
                    :items="pdfs"
                    :rules="requiredRules"
                    item-text="name"
                    item-value="name"
                    :disabled="loading || superLoading"
                    required
                  ></v-select>
                </div>
              </v-col>

              <!-- Select Folder Dropdown -->
              <v-col
                v-if="selectedGroupObj"
                cols="12"
                sm="12"
                md="10"
                class="py-0"
              >
                <div class="d-flex align-center">
                  <v-icon>mdi-folder</v-icon>
                  <span class="ml-1 mr-md-2"> Group Folder: </span>
                  <v-select
                    label="Select Group Folder"
                    class="px-md-4 px-2 ml-1 text-capitalize"
                    v-model="selectedFolder"
                    :items="selectedGroupObj.folders"
                    :rules="requiredRules"
                    :disabled="
                      loading ||
                      superLoading ||
                      !selectedGroup ||
                      !selectedGroupObj
                    "
                    required
                  ></v-select>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-row>
      </v-card>

      <v-card class="mt-12">
        <v-row align="start" justify="center">
          <!-- PDF settings heading -->
          <v-col cols="12" sm="12" md="10">
            <v-card-title>
              <v-icon class="mr-2">mdi-chevron-right</v-icon> Add PDF:
            </v-card-title>
            <v-divider></v-divider>
          </v-col>
          <v-card-text>
            <v-row align="center" justify="center">
              <!-- PDF Title -->
              <v-col cols="12" sm="12" md="10" class="py-0">
                <v-text-field
                  v-model="pdfName"
                  :rules="requiredRules"
                  prepend-icon="mdi-file-edit"
                  color="deep-purple accent-4"
                  label="PDF Name"
                  class="mt-1"
                  type="text"
                  v-on:keydown.enter.prevent
                  :disabled="loading || superLoading"
                  outlined
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <!-- Select Group Dropdown -->
              <v-col cols="12" sm="12" md="10" class="py-0">
                <div class="d-flex align-center">
                  <v-file-input
                    v-model="pdfFile"
                    accept="application/pdf"
                    label="PDF Document"
                    prepend-icon="mdi-file"
                    color="deep-purple accent-4"
                    class="mx-1"
                    :rules="pdfFileRules"
                    :disabled="loading || superLoading"
                    show-size
                    dense
                    outlined
                    required
                  ></v-file-input>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="10" class="pt-0">
                <div class="d-flex align-center justify-end">
                  <v-btn
                    @click="addPDF()"
                    :disabled="loading || superLoading || !valid"
                    color="primary"
                    >Add PDF</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-row>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
const dayjs = require("dayjs");

export default {
  name: "PdfForm",
  props: ["superLoading"],

  computed: {
    selectedGroupObj() {
      if (!this.selectedGroup) {
        return;
      }

      var selectedGroupObj = null;

      this.pdfs.some((pdfGroupObj) => {
        if (this.selectedGroup == pdfGroupObj.name) {
          selectedGroupObj = pdfGroupObj;
          return true;
        }
      });

      return selectedGroupObj;
    },
  },

  data: () => ({
    //setup
    loading: true,
    snackbar: false,
    snackbarText: "",
    error: "",
    valid: false,
    requiredRules: [
      (value) => !!value || "Required.",
      (value) =>
        value.length >= 1 || "Field should be greater than 1 characters",
    ],

    pdfFileRules: [
      (value) => !!value || "Required.",
      (value) =>
        !value ||
        value.size < 5000000 ||
        "Pdf logo image should be less than 5MB!",
    ],

    //pdf form values
    pdfName: "",
    selectedGroup: "",
    selectedFolder: "",
    pdfFile: null,
    pdfs: [], // get from server/store
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    addPDF() {
      this.setLoading(true);

      const payload = {
        pdfObj: {
          id: dayjs().unix(),
          pdfName: this.pdfName,
          selectedGroup: this.selectedGroup,
          selectedFolder: this.selectedFolder,
          pdfURL: null,
        },
        pdfFile: this.pdfFile,
      };

      this.$store
        .dispatch("addPDF", payload)
        .then(() => {
          this.snackbarText = "PDF added successfully :)";
        })
        .catch((err) => {
          this.error = err;
          this.snackbarText = "Error! Please try again :(";
        })
        .finally(() => {
          this.snackbar = true;
          this.selectedGroup = "";
          this.selectedFolder = "";
          this.pdfName = "";
          this.pdfFile = null;
          this.setLoading(false);
        });
    },

    fetchPdfs() {
      // get pdfs from store
      const pdfs = this.$store.getters.pdfGroups;

      //if not found in store
      if (pdfs.length == 0) {
        //get folder names from server
        this.$store
          .dispatch("getPdfDoc")
          .then((res) => {
            if (res) {
              this.pdfs = res.pdfGroups;
            }
          })
          .catch((err) => {
            this.error = err;
          })
          .finally(() => {
            this.setLoading(false);
          });
      } else {
        // if found in store
        this.pdfs = pdfs;
        this.setLoading(false);
      }
    },
  },
  mounted() {
    this.fetchPdfs();
  },
};
</script>
