<template>
  <div id="pdf" class="grey lighten-3 fill-height">
    <v-container>
      <v-row justify="center">
        <!-- title -->
        <h1 class="text-title my-9">
          <v-icon class="text-h3 mr-2" color="blue darken-4">
            mdi-file-plus
          </v-icon>
          Add PDF
        </h1>
      </v-row>
    </v-container>
    <PdfForm :superLoading="superLoading" @setSuperLoading="setSuperLoading" />
  </div>
</template>

<script>
import PdfForm from "@/components/Home/Create/Pdf/PdfForm.vue";
export default {
  name: "Pdf",
  metaInfo: {
    title: "Add PDF",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Add PDF Page description lorem ipsum dolor sit amet.",
      },
    ],
  },
  components: { PdfForm },
  data: () => ({
    superLoading: true,
  }),
  methods: {
    setSuperLoading(value) {
      this.superLoading = value;
    },
  },
  mounted() {
    this.superLoading = false;
  },
};
</script>

<style>
.w-100 {
  width: 100%;
}
</style>